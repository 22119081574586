<template>
    <div class="l-inline l-gap-3 notification-details-card" v-on="$listeners">
        <alarm-bell-sleep-icon v-if="snoozedUntil" width="28" height="28" />

        <alarm-bell-icon v-else width="28" height="28" />

        <div class="l-stack l-gap-1 notification-details-card__info">
            <div>
                <h2 class="l-inline l-center-v">
                    <span>{{ rule.name }}</span>

                    <badge v-if="snoozedUntil">
                        {{ $t('snoozedUntil', [snoozedUntil]) }}
                    </badge>

                    <active-inactive-badge v-else :active="rule.active">
                        {{ $t(rule.active ? 'active' : 'inactive') }}
                    </active-inactive-badge>

                    <badge v-if="rule.delay > 0">
                        {{ $t('delayed') }}
                    </badge>
                </h2>
            </div>

            <p class="notification-details-card__description">
                <i>{{ $t('if') }}</i>

                <span
                    v-for="(condition, i) in conditions"
                    :key="'condition-' + i"
                >
                    <b v-if="condition.type === 'geofence'">
                        {{ $t(condition.position) }}
                        {{ getLocationName(condition.locationId) }}
                    </b>

                    <b v-else-if="condition.type === 'regex'">
                        <template v-if="condition.value === 'battery_status'">
                            {{ $t('shared.measurements.battery_status') }}
                            =
                            {{
                                $t(
                                    'shared.measurementOptions.battery_status.' +
                                        condition.regex
                                )
                            }}
                        </template>

                        <template v-else>
                            {{
                                $t('regexMatches', [
                                    condition.value,
                                    condition.regex,
                                ])
                            }}
                        </template>
                    </b>

                    <b v-else-if="condition.type === 'state'">
                        {{ $t(condition.value) }}
                        {{ $t(condition.state ? 'isOn' : 'isOff') }}
                    </b>

                    <b v-else-if="condition.type === 'offline'">
                        {{
                            $t('offlinefor', {
                                amount:
                                    condition.seconds /
                                    timeOptions[condition.user_unit],
                                unit: condition.user_unit,
                            })
                        }}
                    </b>

                    <b v-else-if="condition.type === 'threshold'">
                        {{ getMeasurementTranslation(condition.value) }}
                        {{ condition.operator }}
                        {{ condition.threshold }}
                        {{ units[condition.value] }}
                    </b>

                    <i v-if="i < conditions.length - 1">
                        {{ $t(rule.conditions.matchMode + 'MatchMode') }}
                    </i>
                </span>

                <i>{{ $t('then') }}</i>

                <span
                    v-for="(value, group, i) in notificationChannelsGrouped"
                    :key="'channel-' + group"
                >
                    <i v-if="i > 0">
                        {{ $t('allMatchMode') }}
                    </i>

                    <b>
                        {{ $t('channel-' + group) }}
                    </b>

                    <i>({{ value }})</i>
                </span>

                <i
                    v-if="
                        Object.keys(notificationChannelsGrouped).length &&
                            (rule.alert_by_email || rule.alert_by_sms)
                    "
                >
                    {{ $t('allMatchMode') }}
                </i>

                <b v-if="rule.alert_by_email"> {{ $t('email to me') }}</b>

                <i v-if="rule.alert_by_email && rule.alert_by_sms">
                    {{ $t('allMatchMode') }}
                </i>

                <b v-if="rule.alert_by_sms"> {{ $t('sms to me') }}</b>
            </p>

            <p>
                <pill v-if="rule.assets.length || rule.all_assets">
                    {{
                        $tc(
                            `shared.assetCount`,
                            rule.assets.length || trackers.length
                        )
                    }}
                </pill>

                <pill v-if="rule.locations.length">
                    {{ $tc('locations', rule.locations.length) }}
                </pill>

                <pill v-if="rule.asset_types.length">
                    {{ $tc('types', rule.asset_types.length) }}
                </pill>
            </p>
        </div>

        <div
            class="l-inline l-start l-gap-2 notification-details-card__actions"
        >
            <label
                v-if="!snoozedUntil"
                class="clickable"
                @click="$event.stopPropagation()"
            >
                <toggle-button
                    class="redesigned"
                    :value="rule.active"
                    sync
                    css-colors
                    :width="40"
                    :height="24"
                    :speed="150"
                    @input="$emit('toggle')"
                />

                <p>
                    {{ $t(rule.active ? 'labelDeactivate' : 'labelActivate') }}
                </p>
            </label>

            <icon-button
                :label="$t('labelResolve')"
                @click.stop="$emit('resolve')"
            >
                <email-check-icon width="24" height="24" />
            </icon-button>

            <div>
                <icon-button
                    :label="$t(snoozedUntil ? 'labelUnsnooze' : 'labelSnooze')"
                    @click.stop="handleContextMenuOpen"
                >
                    <alarm-bell-timer-icon width="24" height="24" />
                </icon-button>

                <context-menu
                    ref="contextMenu"
                    :element-id="`notificationDetailsCardContextMenu${_uid}`"
                    :options="snoozeOptions"
                    @option-clicked="handleContextMenuClick"
                />
            </div>

            <icon-button
                :label="$t('labelDelete')"
                @click.stop="$emit('delete')"
            >
                <trash-bin-icon width="24" height="24" />
            </icon-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import ContextMenu from 'vue-simple-context-menu'
import { ToggleButton } from 'vue-js-toggle-button'

import { measurementHelper } from '@/utils'

import ActiveInactiveBadge from '../redesigned/ActiveInactiveBadge'
import AlarmBellIcon from '../icons/AlarmBellIcon'
import AlarmBellSleepIcon from '../icons/AlarmBellSleepIcon'
import AlarmBellTimerIcon from '../icons/AlarmBellTimerIcon'
import Badge from '../redesigned/Badge'
import EmailCheckIcon from '../icons/EmailCheckIcon'
import IconButton from '../IconButton'
import Pill from '../redesigned/Pill'
import TrashBinIcon from '../icons/TrashBinIcon'

export default {
    name: 'NotificationDetailsCard',
    components: {
        ActiveInactiveBadge,
        AlarmBellIcon,
        AlarmBellSleepIcon,
        AlarmBellTimerIcon,
        Badge,
        ContextMenu,
        EmailCheckIcon,
        IconButton,
        Pill,
        ToggleButton,
        TrashBinIcon,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            units: measurementHelper.units,
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        conditions() {
            return this.rule.conditions.conditions.map(condition => {
                switch (condition.value) {
                    case 'fill_level':
                        return {
                            ...condition,
                            threshold: condition.threshold * 100,
                        }
                    case 'running_time':
                        return {
                            ...condition,
                            threshold: (condition.threshold / 3600).toFixed(2),
                        }
                    case 'voltage_0':
                    case 'voltage_1':
                    case 'voltage_2':
                    case 'voltage_3':
                    case 'voltage_4':
                        return {
                            ...condition,
                            threshold: (condition.threshold / 1000).toFixed(2),
                        }
                    default:
                        return condition
                }
            })
        },
        notificationChannelsGrouped() {
            const email = this.rule.notification_channels
                .filter(item => item.type === 'EMAIL')
                .map(item => item.target)
                .join(', ')
            const sms = this.rule.notification_channels
                .filter(item => item.type === 'SMS')
                .map(item => item.target)
                .join(', ')

            return {
                ...(email.length && { email }),
                ...(sms.length && { sms }),
            }
        },
        snoozeOptions() {
            if (this.snoozedUntil) {
                return [{ name: this.$t('activate'), value: 0 }]
            }

            return [
                { name: this.$t('snoozeOption5m'), value: 1000 * 60 * 5 },
                { name: this.$t('snoozeOption1h'), value: 1000 * 60 * 60 },
                { name: this.$t('snoozeOption4h'), value: 1000 * 60 * 60 * 4 },
                { name: this.$t('snoozeOption1d'), value: 1000 * 60 * 60 * 24 },
                {
                    name: this.$t('snoozeOption1w'),
                    value: 1000 * 60 * 60 * 24 * 7,
                },
            ]
        },
        snoozedUntil() {
            const datetime = moment(this.rule.valid_after)
            return datetime > moment.now()
                ? datetime.format('DD.MM.YYYY HH:mm')
                : null
        },
    },
    methods: {
        getLocationName(locationId) {
            return this.getLocationsSortedByName.find(
                location => location.id === locationId
            )?.name
        },
        getMeasurementTranslation(measurement) {
            const translationKey = `shared.measurements.${measurement}`
            return this.$root.$te(translationKey)
                ? this.$t(translationKey)
                : measurement
        },
        handleContextMenuOpen(e) {
            this.$refs.contextMenu.showMenu(e)
        },
        handleContextMenuClick({ option: { value } }) {
            this.$emit('snooze', value)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "activate": "Activate",
        "active": "Active",
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "channel-email": "Send E-Mail",
        "channel-sms": "Send SMS",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "delayed": "Delayed",
        "email to me": "E-Mail to me",
        "if": "If",
        "inactive": "Inactive",
        "inside": "Inside",
        "isOff": "is Off",
        "isOn": "is On",
        "labelActivate": "Regel aktivieren",
        "labelDeactivate": "Regel deaktivieren",
        "labelDelete": "Regel löschen",
        "labelResolve": "Benachrichtigungen auflösen",
        "labelSnooze": "Regel pausieren",
        "labelUnsnooze": "Pausierung aufheben",
        "locations": "{n} Location | {n} Locations",
        "offlinefor": "offline for {amount} {unit}",
        "outside": "Outside",
        "regexMatches": "{0} matches {1}",
        "sms to me": "SMS to me",
        "snoozeOption1d": "1 day",
        "snoozeOption1h": "1 hour",
        "snoozeOption1w": "1 week",
        "snoozeOption4h": "4 hours",
        "snoozeOption5m": "5 minutes",
        "snoozedUntil": "Snoozed until {0}",
        "then": "then",
        "types": "{n} Type | {n} Types"
    },
    "de": {
        "activate": "Aktivieren",
        "active": "Aktiv",
        "allMatchMode": "und",
        "anyMatchMode": "oder",
        "channel-email": "E-Mail senden",
        "channel-sms": "SMS senden",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "delayed": "Verzögert",
        "email to me": "E-Mail an mich",
        "if": "Wenn",
        "inactive": "Inaktiv",
        "inside": "Innerhalb",
        "isOff": "aus",
        "isOn": "ein",
        "labelActivate": "Regel aktivieren",
        "labelDeactivate": "Regel deaktivieren",
        "labelDelete": "Regel löschen",
        "labelResolve": "Benachrichtigungen auflösen",
        "labelSnooze": "Regel pausieren",
        "labelUnsnooze": "Pausierung aufheben",
        "locations": "{n} Standort | {n} Standorte",
        "offlinefor": "offline für {amount} {unit}",
        "outside": "Ausserhalb",
        "regexMatches": "{0} matches {1}",
        "sms to me": "SMS an mich",
        "snoozeOption1d": "1 Tag",
        "snoozeOption1h": "1 Stunde",
        "snoozeOption1w": "1 Woche",
        "snoozeOption4h": "4 Stunden",
        "snoozeOption5m": "5 Minuten",
        "snoozedUntil": "Pausiert bis {0}",
        "then": "dann",
        "types": "{n} Typ | {n} Typen"
    },
    "it": {
        "activate": "Activate",
        "active": "Attivo",
        "allMatchMode": "e",
        "anyMatchMode": "o",
        "channel-email": "Inviare E-Mail",
        "channel-sms": "Inviare SMS",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "delayed": "Ritardato",
        "email to me": "E-Mail a me",
        "if": "Se",
        "inactive": "Inattivo",
        "inside": "All'interno di",
        "isOff": "è off",
        "isOn": "è on",
        "labelActivate": "Regel aktivieren",
        "labelDeactivate": "Regel deaktivieren",
        "labelDelete": "Regel löschen",
        "labelResolve": "Benachrichtigungen auflösen",
        "labelSnooze": "Regel pausieren",
        "labelUnsnooze": "Pausierung aufheben",
        "locations": "{n} luogo | {n} luoghi",
        "offlinefor": "offline per {amount} {unit}",
        "outside": "All'esterno di",
        "regexMatches": "{0} matches {1}",
        "sms to me": "SMS a me",
        "snoozeOption1d": "1 giorno",
        "snoozeOption1h": "1 ore",
        "snoozeOption1w": "1 settimana",
        "snoozeOption4h": "4 ore",
        "snoozeOption5m": "5 minuti",
        "snoozedUntil": "Sonnecchiato fino a {0}",
        "then": "Poi",
        "types": "{n} tipo | {n} tipi"
    }
}
</i18n>

<style lang="scss" scoped>
.notification-details-card {
    padding: 24px 28px 16px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;

    &__info {
        flex: 1;
    }

    &__description {
        i {
            margin-right: 0.1rem;
        }
    }

    &__actions {
        flex: 0;
        font-size: $fs-smaller;
        color: $color-primary;
        letter-spacing: -0.25px;

        & > * {
            text-align: center;

            label {
                margin-bottom: 6px;
            }
        }

        svg {
            color: $color-primary-light;
        }
    }

    h2 {
        flex-wrap: wrap;
        font-weight: 700;
        font-size: 16px;
        color: $color-text-new;

        & > * {
            margin-right: 8px;
        }
    }

    @include respond-to('for-mobile-down') {
        flex-wrap: wrap;

        &__actions {
            flex: 1 0 100%;
            margin: 16px 0 0;
            justify-content: flex-end;

            & > * {
                flex: 0;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__info {
            flex: 1 0 100%;
            margin: 12px 0 8px;
        }

        &__actions {
            justify-content: space-between;

            & > * {
                &:not(:first-child) {
                    margin: 0;
                }
            }
        }
    }
}
</style>
